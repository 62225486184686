import React from "react";

import { Layout, SEO } from '../../../components/structure';
import { Content, Hero } from '../../../components/blocks';
import AccessibilitySectionLinks from "../../../components/blocks/sectionLinks"

const AccessibilityPage = () => (
  <Layout>
    <SEO title="Accessibility" />
    <Hero title={'Legal - Accessibility at Points'} />
    <Content>
      <div style={{width: '90%', maxWidth: '1080px', margin: 'auto'}}>
      <AccessibilitySectionLinks />
      <h1 style={{textAlign: 'left'}}>Accessible Customer Service</h1>
      <h3 style={{textAlign: 'left'}}>Policy</h3>
      <p style={{textAlign: 'left'}}>
        Points is committed to providing its goods and services in a way that respects the dignity and independence of persons with disabilities.  Points is also committed to giving persons with disabilities the same opportunity to access our goods and services, and allowing them to benefit from the same services, in the same place and in a similar way as other customers. Points will provide our Accessible Customer Service policy to anyone that requests a copy.
      </p>
      <h3 style={{textAlign: 'left'}}>Process</h3>
      <ol>
        <li>
          <h4 style={{textAlign: 'left'}}>Providing goods and services to persons with disabilities</h4>
          Points is committed to excellence in serving all customers including persons with disabilities, and we will carry out our functions and responsibilities in the following areas:
          <ol type="a">
            <li>
              <h5 style={{textAlign: 'left'}}>Communication</h5>
              We will communicate with persons with disabilities in ways that take into account their disability. We will train employees who communicate with customers on how to interact and communicate with people with various types of disabilities.
            </li>
            <li style={{textAlign: 'left'}}>
              <h5 style={{textAlign: 'left'}}>Assistive devices</h5>
              An assistive device is any piece of equipment a person with a disability uses to help them with daily living. Examples include wheelchairs, screen readers, listening devices or canes. Points is committed to serving persons with disabilities who use assistive devices to obtain, use or benefit from our goods and services. We will ensure that our employees are trained and familiar with various assistive devices that may be used by customers with disabilities while accessing our goods or services.
            </li>
          </ol>
        </li>
        <li>
          <h4 style={{textAlign: 'left'}}>Use of service animals and support persons</h4>
          <p style={{textAlign: 'left'}}>Points welcomes persons with disabilities who are accompanied by a service animal on the parts of our premises that are open to customers and other third parties. We will also ensure that all employees and others dealing with customers and third parties are properly trained in how to interact with persons with disabilities who are accompanied by a service animal.</p>
          <p style={{textAlign: 'left'}}>Any person with a disability who is accompanied by a support person will be allowed to enter Points premises with his or her support person. At no time will a person with a disability who is accompanied by a support person be prevented from having access to his or her support person while on our premises.</p>
        </li>
        <li>
          <h4 style={{textAlign: 'left'}}>Notice of temporary disruption</h4>
          <p style={{textAlign: 'left'}}>Points will provide customers with notice in the event of a planned or unexpected disruption in the facilities or services usually used by persons with disabilities. This notice will include information about the reason for the disruption, the anticipated duration and a description of alternative facilities or services, if available. The notice will be displayed in a prominent place.</p>
        </li>
        <li>
          <h4 style={{textAlign: 'left'}}>Training for employees</h4>
          Points will provide training to all employees and others who deal with customers or other third parties on their behalf, and all those who are involved in the development and approval of customer service policies, practices and procedures. This training will be provided as part of the onboarding process.
          <ul style={{textAlign: 'left'}}>
            Training will include the following:
            <li>the purposes of the Accessibility for Ontarians with Disabilities Act, 2005, and the requirements of the customer service standard;</li>
            <li>how to interact and communicate with persons with various types of disabilities;</li>
            <li>how to interact with persons with disabilities who use an assistive device or require the assistance of a service animal or a support person;</li>
            <li>how to use equipment or assistive devices available on our premises, or that we otherwise provide, that may help with the provision of goods or services to people with disabilities;</li>
            <li>what to do if a person with a disability is having difficulty accessing Points’ goods and services; and</li>
            <li>Points’ practices and procedures relating to the customer service standard.</li>
          </ul>
          All relevant employees will be trained on policies, practices and procedures that affect the way goods and services are provided to persons with disabilities.
        </li>
      </ol>
      <p style={{textAlign: 'left'}}>Employees will also be trained on an ongoing basis when changes are made to these policies, practices and procedures.</p>
      </div>
    </Content>
  </Layout>
);

export default AccessibilityPage;
